import {
    FormControl,
    FormControlLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, {useContext} from "react";
import {SystemContext} from "../../SystemContext";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";



const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff', ...theme.typography.body2,
    padding: theme.spacing(1), // textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const ServerConfigurationSection = () => {
    const {setPrimaryView} = useContext(SystemContext);
    const {viewSettings} = useContext(SystemContext);
    console.log(viewSettings);

    const handlePrimaryViewChange = (event) => {
        setPrimaryView(viewSettings['availableViews'][event.target.value].name);
    }


    return (<Box sx={{width: "75%", alignItems: "center", alignSelf: "center", ml: "auto", mr: "auto"}}>
        <Grid container={true} spacing={2} direction={"row"} alignItems={"center"} justifyContent={"center"}>
            <Grid item={true} md={12} xs={12}>
                <Item>
                    <FormControl variant={"standard"} sx={{width: "100%", alignItems: "center", m: 1, minWidth: 120}}>
                        <FormControlLabel label={"Primary View"}
                                          control={<Select labelId={"primary-view-lbl"}
                                                           value={viewSettings['availableViews'].map(view => view.name).indexOf(viewSettings['primaryView'])}
                                                           onChange={handlePrimaryViewChange}
                                                           label={"Primary View"} sx={{ml: 3}}>
                                              {viewSettings['availableViews'].map(view => view.name).map((v, i) => {
                                                  return <MenuItem value={i}>{v}</MenuItem>
                                              })}
                                          </Select>}
                                          labelPlacement={"start"}
                                          id={"primary-view-lbl"}/>

                    </FormControl>
                </Item>
            </Grid>
            <Grid item={true} md={12} xs={12}><Item>
                <TableContainer>
                    <Table sx={{minWidth: 600}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align={"center"}>
                                    View name
                                </TableCell>
                                <TableCell align={"center"}>
                                    View rule
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {viewSettings['availableViews'].map(view => {
                                return (<TableRow key={view.name}>
                                    <TableCell align={'center'} component="th" scope={"row"}>{view.name}</TableCell>
                                    <TableCell align={"center"} component="th"
                                               scope={"row"}>{`Every ${view.delta} ${view.type}(s)`}</TableCell>
                                </TableRow>);
                            })}
                        </TableBody>
                    </Table></TableContainer>
            </Item></Grid>
        </Grid>
    </Box>);
}