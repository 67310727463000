import React from 'react';
import {createContext} from "react";
import {loadSettings} from "./SettingsService";
import useColorStorage from "./Hooks/useColorStorage";
import useViewsStorage from "./Hooks/useViewsStorage";

const SystemContext = createContext(null);

const System = (props) => {
    const [colors, setColors] = useColorStorage();
    const [viewSettings, setViewsSettings] = useViewsStorage();

    const setPrimaryView = (newPrimaryView) => {
        setViewsSettings({
            ...viewSettings, "primaryView": newPrimaryView
        });
    };

    const setAvailableViews = (newAvailableViews) => {
        setViewsSettings({
            ...viewSettings, "availableViews": newAvailableViews
        });
    };

    React.useEffect(() => {
        loadSettings().then(response => {
            const colorsList = response.data['keyColorPairs'].map(c => {
                return {
                    [c["key"]]: c["color"]
                };
            });
            const colorsObj = Object.assign({}, ...colorsList);
            setColors(colorsObj);

        }).catch(err => console.log(err));
    }, []);

    React.useEffect(() => {
        loadSettings().then(response => {
            const settings = response.data['viewSetting'];
            console.log(response);
            setViewsSettings({"primaryView": settings['primaryView'], "availableViews": settings["availableViews"]});
            console.log(settings);
            console.log(settings['availableViews']);
        }).catch(err => console.log(err));
    }, []);
    return (<SystemContext.Provider
        value={{colors, setColors, viewSettings, setViewsSettings, setPrimaryView, setAvailableViews}}>
        {props.children}
    </SystemContext.Provider>);
}


export {System, SystemContext};