export function create_UUID() {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export function mergeWithNewId(list1, list2) {
    let merged = [];
    for (let i = 0; i < list1.length; i++) {
        merged.push({id: create_UUID(), ...list1[i], ...(list2.find(itm => itm.Date === list1[i].Date))});
    }

    return merged;
}

export function spaceToDash(s) {
    return s.toString().replaceAll(' ', '-');
}

export function convertToCsv(arr) {
    const array = [Object.keys(arr[0])].concat(arr)

    return array.map(it => {
        return Object.values(it).toString()
    }).join('\n')
}

export function formatDate(date) {
    date = new Date(date);
    if (!(date instanceof Date)) {
        throw Error("invalid argument: date must be a Date");
    }
    return date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
    }).split(' ').join('/');
}