// noinspection JSValidateTypes

import {Box, Tab, Tabs} from "@mui/material";
import {useState} from "react";
import React from "react";


import {ChartsConfigurationSection} from "./sections/ChartsConfigurationSection";
import {ServerConfigurationSection} from "./sections/ServerConfigurationSection";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (<div
        role="tab-panel"
        hidden={value !== index}
        {...other}
    >
        {value === index && (<Box sx={{width: "100%", p: 3}}>
            {children}
        </Box>)}
    </div>);
}


function createBasicTabProps(index) {
    return {
        id: `tab-${index}`, 'aria-controls': `tab-panel-${index}`,
    };
}

export default function SystemConfigurationPage() {
    const CHARTS_CONFIG_VAL = "Charts Configuration";
    const SERVER_CONFIG_VAL = "Server Configuration";
    const [tabValue, setTabValue] = useState(0);


    const handleTabChange = (event, newValue) => {
        console.log(newValue);
        setTabValue(newValue);
    };


    return <Box
        sx={{width: "100%", alignContent: "center", justifyContent: "center", alignSelf: "center", ml: "auto"}}>
        <Box display={"flex"}
             sx={{alignContent: "center", justifyContent: "center", borderBottom: 1, borderColor: 'divider'}}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor={'secondary'}
                indicatorColor={'secondary'}
            >
                <Tab label={SERVER_CONFIG_VAL} {...createBasicTabProps(0)} />
                <Tab label={CHARTS_CONFIG_VAL} {...createBasicTabProps(1)} >
                </Tab>
            </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
            <ServerConfigurationSection/>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
            <ChartsConfigurationSection/>
        </TabPanel>
    </Box>
}