import StatsContentSection from "./sections/StatsContentSection";
import * as React from "react";
import {useParams} from "react-router";
import {getReportById} from "../CustomerStatsService";
import {useState} from "react";

export default function SavedReportPage() {
    let {reportId} = useParams();
    console.log(reportId);
    const [report, setReport] = useState({});
    React.useEffect(() => {
        getReportById(reportId).then(response => {
            console.log(response.data);
            setReport(response.data);

        })
    }, [reportId]);
    if (report && Object.keys(report).length === 0 && Object.getPrototypeOf(report) === Object.prototype) {
        return <div/>;
    } else {
        return (<StatsContentSection totalTrial={report.totalTrialUsers}
                                     totalCommercial={report.totalCommercial} totalNewTrial={report.newTrialUsers}
                                     totalFree={report.totalFree} totalConversions={report.totalConversions}/>

        );
    }
}