import React, {useContext} from 'react';
import {Route, Routes, Navigate} from "react-router-dom";
import DashboardPage from '../Pages/DashboardPage';
import SavedReportPage from "../Pages/SavedReportPage";
import SystemConfigurationPage from "../Pages/SystemConfigurationPage";
import LoginPage from "../Pages/LoginPage";

import {AuthContext} from "../Auth/AuthService";
import {DocumentationPage} from "../Pages/DocumentationPage";
import CreateReportPage from "../Pages/CreateReportPage";

function RoutesComponent({onPageChanged}) {
    const {isLoggedIn} = useContext(AuthContext);
    const Wrapper = ({children, newTitle}) => {
        if (isLoggedIn()) {
            onPageChanged(newTitle);
            return children;
        } else {
            return <Navigate to={"/login"} replace={true}/>
        }
    };
    return (<Routes>
        <Route path='/' element={<Navigate to={"/dashboard"} replace={true}/>}/>
        <Route path='/dashboard' element={<Wrapper newTitle={"Dashboard"}>
            <DashboardPage/>
        </Wrapper>}/>
        <Route path='/createReport' element={<Wrapper newTitle={"Create new report"}>
            <CreateReportPage/>
        </Wrapper>}/>
        <Route path='/sysconfig' element={<Wrapper newTitle={"System Configuration"}>
            <SystemConfigurationPage/>
        </Wrapper>}/>
        <Route path='/documentation/:documentationPage' element={<Wrapper newTitle={""}>
            <DocumentationPage/>
        </Wrapper>}/>
        <Route path='/savedReport/:reportId' element={<Wrapper newTitle={""}>
            <SavedReportPage/>
        </Wrapper>}/>
        <Route path='/login' element={<LoginPage/>}/>
    </Routes>);
}

export default RoutesComponent;