import {useEffect, useState} from "react";
import {retrieveCustomerStats} from "../CustomerStatsService";
import {processCustomerStats} from "../Utils/ProcessCustomerStats";

export const useCustomerStats = (view, onSuccess, onError) => {
    console.log(view);
    const [customerStatsData, setCustomerStatsData] = useState({
        totalCommercial: [],
        newCommercial: [],
        totalTrial: [],
        newTrial: [],
        conversionsToCommercial: [],
        conversionsToFree: [],
        totalFree: [],
        upgrades: [],
        downgrades: [],
        illegalTrial: []
    });

    useEffect(() => {
        if (view !== undefined && Object.keys(view).length !== 0 && Object.getPrototypeOf(view) === Object.prototype) {
            console.log(view);
            retrieveCustomerStats(view).then(response => {
                onSuccess();
                let data = response.data;
                let stats = processCustomerStats(data);
                console.log(stats);
                setCustomerStatsData(stats);
            }).catch(err => {
                if (err) {
                    onError(err);
                }
            });
        }
    }, [view]);

    return customerStatsData;
}