import * as React from "react";
import {useContext, useState} from "react";
import {useNavigate} from "react-router";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ListItemText from "@mui/material/ListItemText";
import {AuthContext} from "../Auth/AuthService";

export default function SecondaryListItems({onClickCallback}) {
    const [reportIds] = useState([]);
    const nav = useNavigate();
    const {isLoggedIn} = useContext(AuthContext);

    function handleClick(reportId) {
        console.log(reportId);
        nav('/savedReport/' + reportId);
        onClickCallback(reportId);
    }

    return (<React.Fragment>
        <ListSubheader component="div" inset>
            Saved reports
        </ListSubheader>

        {isLoggedIn() && reportIds.map(reportId => {
            return (
                <ListItemButton key={reportId} onClick={() => handleClick(reportId)}>
                    <ListItemIcon>
                        <AssignmentIcon/>
                    </ListItemIcon>
                    <ListItemText primary={reportId}/>
                </ListItemButton>
            );
        })
        }
    </React.Fragment>);
};