import {useEffect, useState} from "react";
import {updateViewSettings} from "../SettingsService";

export default function useViewsStorage() {
    const [val, setVal] = useState({"primaryView": "", "availableViews": []});

    useEffect(() => {
        if (val !== undefined || val['primaryView'] === "" || val['availableViews'].length === 0) {
            updateViewSettings(val).then(r => console.log(r)).catch(err => console.log(err));
        }
    }, [val]);

    return [val, setVal];
}