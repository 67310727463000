import {useEffect, useState} from "react";
import {postColorsSchema} from "../SettingsService";

export default function useColorStorage() {
    const [val, setVal] = useState();

    useEffect(() => {
        if (val !== undefined && val != null) {
            postColorsSchema(val).then(r => console.log(r));
        }
    }, [val]);

    return [val, setVal];
}