import {
    Alert,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Snackbar,
    Stack,
    TextField
} from "@mui/material";
import React, {useState, useContext} from 'react';
import Button from "@mui/material/Button";

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {AuthContext} from "../Auth/AuthService";
import {useNavigate} from "react-router";
import Divider from "@mui/material/Divider";

const PASSWORDS_DO_NOT_MATCH = "passwords do not match";
const EMPTY_PASSWORD = "password cannot be empty";

const ResetPasswordDialog = ({open, handleCloseDialog}) => {
    const [userName, setUserName] = useState("");
    const [step, setStep] = useState(0);
    const [emailCode, setEmailCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRetyped, setNewPasswordRetyped] = useState("");
    const [validPassword, setValidPassword] = useState({
        isValid: true, helperText: ""
    });
    const {resetPassword, confirmPassword} = useContext(AuthContext);

    const handleNext = () => {
        if (step === 0) {
            resetPassword(userName).then(ret => {
                console.log(ret);
            }).catch(err => console.log(err));
            setStep(1);
        } else if (step === 1) {
            confirmPassword(userName, emailCode, newPassword);
            handleCloseDialog();
            setStep(0);
        }
    }

    React.useEffect(() => {
        if (newPassword === "" || newPassword === undefined) {
            setValidPassword({
                helperText: EMPTY_PASSWORD, isValid: false
            });
        } else if (newPasswordRetyped !== newPassword) {
            setValidPassword({
                helperText: PASSWORDS_DO_NOT_MATCH, isValid: false
            });
        } else {
            setValidPassword({
                helperText: "", isValid: true
            });
        }
    }, [newPassword, newPasswordRetyped]);

    return (<Dialog open={open}>
        <DialogTitle>Reset Password</DialogTitle>
        {step === 0 ? <DialogContent>
            <DialogContentText>Introduce your username:</DialogContentText>
            <TextField value={userName} onChange={event => setUserName(event.target.value)}
                       variant={"standard"}/>
        </DialogContent> : <DialogContent>
            <Alert severity={"info"}>A code has been sent at the email associated with the given account. Please
                check your email to retrieve the code!</Alert>
            <DialogContentText>Please select a new password. It must contain:
                <ul>
                    <li>at least <b>8 characters</b></li>
                    <li>at least <b>one special character</b></li>
                    <li>at least <b>one uppercase character</b></li>
                    <li>at least <b>one lowercase character</b></li>
                </ul>
            </DialogContentText>
            <FormControl>
                <FormControlLabel
                    control={<TextField value={userName} size={"small"} variant={"standard"} disabled={true}
                                        contentEditable={"false"} sx={{ml: 2}}/>}
                    labelPlacement={"start"}
                    label={"Username"}/>
                <FormControlLabel
                    control={<TextField size={"small"} type={"password"} variant={"standard"} sx={{ml: 2}}
                                        onChange={event => setEmailCode(event.target.value)}/>}

                    labelPlacement={"start"}
                    label={"Reset code (from email)"}/>
                <FormControlLabel
                    control={<TextField size={"small"} type={"password"} variant={"standard"} sx={{ml: 2}}
                                        onChange={event => setNewPassword(event.target.value)}/>}

                    labelPlacement={"start"}
                    label={"New password"}/>
                <FormControlLabel
                    control={<TextField size={"small"} type={"password"} variant={"standard"} sx={{ml: 2}}
                                        error={!validPassword.isValid} helperText={validPassword.helperText}
                                        onChange={event => setNewPasswordRetyped(event.target.value)}/>}
                    labelPlacement={"start"}
                    label={"New password (again)"}/>
            </FormControl>
        </DialogContent>}
        <DialogActions>
            <Button onClick={() => {
                setStep(0);
                handleCloseDialog()
            }}>Cancel</Button>
            <Button onClick={() => handleNext()}>{step === 0 ? "Next" : "Reset Password"}</Button>
        </DialogActions>
    </Dialog>);
}

const LoginPage = () => {

    const nav = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRetyped, setNewPasswordRetyped] = useState("");
    const [validPassword, setValidPassword] = useState({
        isValid: true, helperText: ""
    });
    const [challengeCallback, setChallengeCallback] = useState();

    const [openToastNotification, setOpenToastNotification] = useState(false);
    const [alert, setAlert] = useState({alertMessage: "", alertSev: "error"});
    const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const {authenticateUser, resetPassword} = useContext(AuthContext);

    React.useEffect(() => {
        if (newPassword === "" || newPassword === undefined) {
            setValidPassword({
                helperText: EMPTY_PASSWORD, isValid: false
            });
        } else if (newPasswordRetyped !== newPassword) {
            setValidPassword({
                helperText: PASSWORDS_DO_NOT_MATCH, isValid: false
            });
        } else {
            setValidPassword({
                helperText: "", isValid: true
            });
        }
    }, [newPassword, newPasswordRetyped]);

    function handleCloseNotification() {
        setOpenToastNotification(false);
    }

    function handleCloseDialog(submitNewPassword) {
        if (submitNewPassword) {
            if (newPassword !== newPasswordRetyped) {
                setAlert({alertMessage: "Passwords do not match", alertSev: "error"});
                setOpenToastNotification(true);
            } else {
                console.log("trying to complete challenge. newPassword is: " + newPassword);
                console.log(challengeCallback);
                try {
                    challengeCallback(newPassword);
                } catch (e) {
                    console.log(e);
                    setAlert({alertMessage: e.message, alertSev: "error"});
                    setOpenToastNotification(true);
                }
            }
        }
        setIsNewPasswordRequired(false);
    }

    function onNewPasswordRequired(challengeCallback) {
        console.log("test");
        setChallengeCallback(() => challengeCallback);
        setIsNewPasswordRequired(true);
    }

    const handleResetPassword = () => {
        setIsResetPassword(true);
    }

    const onSubmit = (event) => {
        event.preventDefault();
        authenticateUser(username, password, onNewPasswordRequired).then(result => {
            console.log(result);
            setOpenToastNotification(false);
            nav("/dashboard");
        }).catch(err => {
            console.log(err.message);
            setAlert({alertMessage: err.message, alertSev: "error"});
            setOpenToastNotification(true);
        });
    };

    return (<React.Fragment>
        <div style={{width: "100%"}} align={"center"}>
            <Box display={"flex"} component={"form"} sx={{
                justifyContent: 'center',
                alignSelf: 'center',
                width: "100%",
                alignContent: "center",
                ml: "auto",
                '& .MuiTextField-root': {m: 1, width: "25%"},
            }}>
                <TextField onChange={event => setUsername(event.target.value)} variant={"outlined"} sx={{mr: 2}}
                           required={true}
                           name={"password"} label={"Username"}
                           type={"text"}/>
                <TextField onChange={event => setPassword(event.target.value)} variant={"outlined"} sx={{ml: 2}}
                           required={true}
                           name={"password"} label={"Password"}
                           type={"password"}/>
            </Box>
            <Stack>
                <Button onClick={onSubmit} variant={"contained"} endIcon={<ExitToAppIcon/>}
                        sx={{mt: 3, mr: 'auto', ml: 'auto', width: 'min-content'}}>
                    Login
                </Button>
                <Button onClick={handleResetPassword} variant={"text"} sx={{mt: 3, mr: 'auto', ml: 'auto'}}>Forgot
                    password</Button>
            </Stack>
        </div>
        <Snackbar open={openToastNotification} autoHideDuration={1500}
                  onClose={handleCloseNotification} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
            <Alert onClose={handleCloseNotification} severity={alert.alertSev}
                   sx={{width: '100%'}}>
                {alert.alertMessage}
            </Alert>
        </Snackbar>
        <Dialog open={isNewPasswordRequired}>
            <DialogTitle>New password required<Divider/></DialogTitle>
            <DialogContent>
                <DialogContentText>Please select a new password. It must contain:
                    <ul>
                        <li>at least 8 characters</li>
                        <li>at least one special character</li>
                        <li>at least one uppercase character</li>
                        <li>at least one lowercase character</li>
                    </ul>
                </DialogContentText>
                <Alert severity={"warning"}>The password <b>cannot</b> be changed later</Alert>
                <FormControl>
                    <FormControlLabel
                        control={<TextField value={username} size={"small"} variant={"standard"} disabled={true}
                                            contentEditable={"false"} sx={{ml: 2}}/>}
                        labelPlacement={"start"}
                        label={"Username"}/>
                    <FormControlLabel
                        control={<TextField size={"small"} type={"password"} variant={"standard"} sx={{ml: 2}}
                                            onChange={event => setNewPassword(event.target.value)}/>}

                        labelPlacement={"start"}
                        label={"New password"}/>
                    <FormControlLabel
                        control={<TextField size={"small"} type={"password"} variant={"standard"} sx={{ml: 2}}
                                            error={!validPassword.isValid} helperText={validPassword.helperText}
                                            onChange={event => setNewPasswordRetyped(event.target.value)}/>}
                        labelPlacement={"start"}
                        label={"New password (again)"}/>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDialog(false)}>Cancel</Button>
                <Button disabled={!validPassword.isValid} onClick={() => handleCloseDialog(true)}>Submit</Button>
            </DialogActions>
        </Dialog>
        <ResetPasswordDialog open={isResetPassword} handleCloseDialog={() => setIsResetPassword(false)}/>
    </React.Fragment>);
}

export default LoginPage;