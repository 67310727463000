import axios from "axios";
import {getSession} from "./Auth/AuthService";
import {SERVER_URL} from "./Constants/Constants";

import Pool from "./Auth/UserPool";

const COLOR_SCHEMA_URL = `${SERVER_URL}/preferences/color-schema`;

const VIEWS_URL = `${SERVER_URL}/preferences/views-settings`;

const SETTINGS_URL = `${SERVER_URL}/settings`

async function createAuthHeader() {
    const session = await getSession();
    return 'Bearer ' + session.getAccessToken().getJwtToken();
}

export async function loadSettings() {
    const authorization = await createAuthHeader();
    const username = Pool.getCurrentUser().getUsername();

    return axios.get(SETTINGS_URL, {headers: {Authorization: authorization}, params: {username: username}});
}

export async function postColorsSchema(colorSchema) {
    const authorization = await createAuthHeader();

    let colorPairs = Object.entries(colorSchema).map(keyColorPair => {

        return {
            key: keyColorPair[0], color: keyColorPair[1]
        }
    });

    const response = await loadSettings();
    const prevSettings = response['data'];


    return axios.put(SETTINGS_URL, {...prevSettings, "keyColorPairs": colorPairs, "updateOnlyViewSettings": false}, {
        headers: {
            Authorization: authorization, 'Content-Type': 'application/json'
        }
    });
}

export async function updateViewSettings(viewsSetting) {
    const authorization = await createAuthHeader();
    const dataToBeSent = {
        primaryView: viewsSetting['primaryView'], availableViews: viewsSetting['availableViews'].map((view) => {
            return {
                name: view['name'], delta: view['delta'], type: view['type'], active: view['active']
            };
        })
    };

    const response = await loadSettings();
    const prevSettings = response['data'];

    return axios.put(SETTINGS_URL, {
        ...prevSettings, "viewSetting": dataToBeSent, "updateOnlyViewSettings": true
    }, {headers: {Authorization: authorization}});
}
