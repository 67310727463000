import {
    BASIC_ANNUAL_TO_FREE,
    BASIC_MONTHLY_TO_FREE,
    BASIC_TO_PRO,
    BASIC_TO_ULTIMATE,
    DATE,
    EDUCATIONAL_TO_FREE,
    FIRST_USAGE_TO_BASIC_ANNUAL,
    FIRST_USAGE_TO_BASIC_MONTHLY,
    FIRST_USAGE_TO_PRO_ANNUAL,
    FIRST_USAGE_TO_PRO_MONTHLY,
    FIRST_USAGE_TO_ULTIMATE_ANNUAL,
    FREE_TO_BASIC_ANNUAL,
    FREE_TO_BASIC_MONTHLY,
    FREE_TO_PRO_ANNUAL,
    FREE_TO_PRO_MONTHLY,
    FREE_TO_ULTIMATE_ANNUAL,
    ILLEGAL_TRIAL_30_DAYS,
    ILLEGAL_TRIAL_30_PLUS_DAYS,
    ILLEGAL_TRIAL_3_DAYS,
    ILLEGAL_TRIAL_7_DAYS,
    NEW_COMMERCIAL_ANNUAL_BASIC,
    NEW_COMMERCIAL_ANNUAL_PRO,
    NEW_COMMERCIAL_ANNUAL_ULTIMATE,
    NEW_COMMERCIAL_MONTHLY_BASIC,
    NEW_COMMERCIAL_MONTHLY_PRO,
    NON_COMM_TO_FREE,
    POC_TO_FREE,
    PRO_ANNUAL_TO_FREE,
    PRO_MONTHLY_TO_FREE,
    PRO_TO_BASIC,
    PRO_TO_ULTIMATE,
    TOTAL_COMMERCIAL_ANNUAL_BASIC,
    TOTAL_COMMERCIAL_ANNUAL_PRO,
    TOTAL_COMMERCIAL_ANNUAL_ULTIMATE,
    TOTAL_COMMERCIAL_MONTHLY_BASIC,
    TOTAL_COMMERCIAL_MONTHLY_PRO,
    TOTAL_FREE,
    TOTAL_NEW_TRIAL,
    TOTAL_TRIAL,
    TRIAL_TO_BASIC_ANNUAL,
    TRIAL_TO_BASIC_MONTHLY,
    TRIAL_TO_FREE,
    TRIAL_TO_PRO_ANNUAL,
    TRIAL_TO_PRO_MONTHLY,
    TRIAL_TO_ULTIMATE_ANNUAL,
    ULTIMATE_ANNUAL_TO_FREE,
    ULTIMATE_TO_BASIC,
    ULTIMATE_TO_PRO
} from "../Constants/Constants";

export const processCustomerStats = (responseData) => {
    const totalCommercial = responseData.map(rData => {
        const totalCommercialUsers = rData["total_assigned_commercial_seats"];
        return {
            [DATE]: rData.date.split('T')[0],
            [TOTAL_COMMERCIAL_MONTHLY_BASIC]: totalCommercialUsers["total_monthly_subscriptions"]["CORE"],
            [TOTAL_COMMERCIAL_MONTHLY_PRO]: totalCommercialUsers["total_monthly_subscriptions"]["PRO"],
            [TOTAL_COMMERCIAL_ANNUAL_BASIC]: totalCommercialUsers["total_annual_subscriptions"]["CORE"],
            [TOTAL_COMMERCIAL_ANNUAL_PRO]: totalCommercialUsers["total_annual_subscriptions"]["PRO"],
            [TOTAL_COMMERCIAL_ANNUAL_ULTIMATE]: totalCommercialUsers["total_annual_subscriptions"]["ENTERPRISE"],
            total: totalCommercialUsers["total_value"]
        };
    });
    const newCommercial = responseData.map(rData => {
        const newCommercialUsers = rData["new_commercial_users"];
        return {
            [DATE]: rData.date.split('T')[0],
            [NEW_COMMERCIAL_MONTHLY_BASIC]: newCommercialUsers["new_monthly_commercial_users"]["new_monthly_basic_users"],
            [NEW_COMMERCIAL_MONTHLY_PRO]: newCommercialUsers["new_monthly_commercial_users"]["new_monthly_pro_users"],
            [NEW_COMMERCIAL_ANNUAL_BASIC]: newCommercialUsers["new_annual_commercial_users"]["new_annual_basic_users"],
            [NEW_COMMERCIAL_ANNUAL_PRO]: newCommercialUsers["new_annual_commercial_users"]["new_annual_pro_users"],
            [NEW_COMMERCIAL_ANNUAL_ULTIMATE]: newCommercialUsers["new_annual_commercial_users"]["new_annual_ultimate_users"],
        }
    });
    const conversionsToCommercial = responseData.map(rData => {
        const totalConversions = rData["total_conversions_to_commercial"];
        return {
            [DATE]: rData.date.split('T')[0],
            [TRIAL_TO_BASIC_MONTHLY]: totalConversions["conversions_to_monthly_commercial"]["from_trial"]["to_basic"],
            [TRIAL_TO_PRO_MONTHLY]: totalConversions["conversions_to_monthly_commercial"]["from_trial"]["to_pro"],
            [TRIAL_TO_BASIC_ANNUAL]: totalConversions["conversions_to_annual_commercial"]["from_trial"]["to_basic"],
            [TRIAL_TO_PRO_ANNUAL]: totalConversions["conversions_to_annual_commercial"]["from_trial"]["to_pro"],
            [TRIAL_TO_ULTIMATE_ANNUAL]: totalConversions["conversions_to_annual_commercial"]["from_trial"]["to_ultimate"],
            [FREE_TO_BASIC_MONTHLY]: totalConversions["conversions_to_monthly_commercial"]["from_free"]["to_basic"],
            [FREE_TO_PRO_MONTHLY]: totalConversions["conversions_to_monthly_commercial"]["from_free"]["to_pro"],
            [FREE_TO_BASIC_ANNUAL]: totalConversions["conversions_to_annual_commercial"]["from_free"]["to_basic"],
            [FREE_TO_PRO_ANNUAL]: totalConversions["conversions_to_annual_commercial"]["from_free"]["to_pro"],
            [FREE_TO_ULTIMATE_ANNUAL]: totalConversions["conversions_to_annual_commercial"]["from_free"]["to_ultimate"],
            [FIRST_USAGE_TO_BASIC_MONTHLY]: totalConversions["conversions_to_monthly_commercial"]["from_first_use"]["to_basic"],
            [FIRST_USAGE_TO_PRO_MONTHLY]: totalConversions["conversions_to_monthly_commercial"]["from_first_use"]["to_pro"],
            [FIRST_USAGE_TO_BASIC_ANNUAL]: totalConversions["conversions_to_annual_commercial"]["from_first_use"]["to_basic"],
            [FIRST_USAGE_TO_PRO_ANNUAL]: totalConversions["conversions_to_annual_commercial"]["from_first_use"]["to_pro"],
            [FIRST_USAGE_TO_ULTIMATE_ANNUAL]: totalConversions["conversions_to_annual_commercial"]["from_first_use"]["to_ultimate"]
        }
    });
    const conversionsToFree = responseData.map(rData => {
        const totalConversionsToFree = rData["total_conversions_to_free"];
        const conversionsFromCommercial = totalConversionsToFree["from_commercial"];
        const conversionsFromTrial = totalConversionsToFree["from_trial"];

        return {
            [DATE]: rData.date.split('T')[0],
            [BASIC_MONTHLY_TO_FREE]: conversionsFromCommercial["from_monthly"]["from_basic"],
            [PRO_MONTHLY_TO_FREE]: conversionsFromCommercial["from_monthly"]["from_pro"],
            [BASIC_ANNUAL_TO_FREE]: conversionsFromCommercial["from_annual"]["from_basic"],
            [PRO_ANNUAL_TO_FREE]: conversionsFromCommercial["from_annual"]["from_pro"],
            [ULTIMATE_ANNUAL_TO_FREE]: conversionsFromCommercial["from_annual"]["from_ultimate"],
            [TRIAL_TO_FREE]: conversionsFromTrial,
            [POC_TO_FREE]: totalConversionsToFree["from_poc"],
            [EDUCATIONAL_TO_FREE]: totalConversionsToFree["from_educational"],
            [NON_COMM_TO_FREE]: totalConversionsToFree["from_non_commercial"]
        }
    });
    const totalTrial = responseData.map(rData => {
        return {
            [DATE]: rData.date.split('T')[0],
            [TOTAL_TRIAL]: rData["total_trial_users"],
            [TRIAL_TO_FREE]: rData["total_conversions_to_free"]["from_trial"]
        };
    });
    const newTrial = responseData.map(rData => {
        return {
            [DATE]: rData.date.split('T')[0], [TOTAL_NEW_TRIAL]: rData["total_new_trial_users"]
        };
    });
    const totalFree = responseData.map(rData => {
        return {
            [DATE]: rData.date.split('T')[0], [TOTAL_FREE]: rData["total_free_users"]
        };
    });
    const upgrades = responseData.map(rData => {
        const ups = rData["upgrades_and_downgrades"]["upgrades"];
        return {
            [DATE]: rData.date.split('T')[0],
            [BASIC_TO_PRO]: ups["basic_pro_upgrade"],
            [BASIC_TO_ULTIMATE]: ups["basic_ultimate_upgrade"],
            [PRO_TO_ULTIMATE]: ups["pro_ultimate_upgrade"]
        }
    });
    const downgrades = responseData.map(rData => {
        const downs = rData["upgrades_and_downgrades"]["downgrades"];
        return {
            [DATE]: rData.date.split('T')[0],
            [ULTIMATE_TO_BASIC]: downs["ultimate_basic_downgrade"],
            [ULTIMATE_TO_PRO]: downs["ultimate_pro_downgrade"],
            [PRO_TO_BASIC]: downs["pro_basic_downgrade"]
        }
    });

    const illegalTrial = responseData.map(rData => {
        const illegalTrials = rData["total_illegal_trials"];
        return {
            [DATE]: rData.date.split('T')[0],
            [ILLEGAL_TRIAL_3_DAYS] : illegalTrials["illegal_trials_1_day"],
            [ILLEGAL_TRIAL_7_DAYS] : illegalTrials["illegal_trials_3_days"],
            [ILLEGAL_TRIAL_30_DAYS] : illegalTrials["illegal_trials_7_days"],
            [ILLEGAL_TRIAL_30_PLUS_DAYS] : illegalTrials["illegal_trials_30_days"]
        }
    })

    return {
        totalCommercial: totalCommercial,
        newCommercial: newCommercial,
        totalTrial: totalTrial,
        newTrial: newTrial,
        conversionsToCommercial: conversionsToCommercial,
        conversionsToFree: conversionsToFree,
        totalFree: totalFree,
        upgrades: upgrades,
        downgrades: downgrades,
        illegalTrial: illegalTrial
    };
}