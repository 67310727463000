import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {CircleRounded} from "@mui/icons-material";
import React, {useContext} from "react";
import validateColor from "validate-color";
import {SystemContext} from "../../SystemContext";

export const ChartsConfigurationSection = () => {
    const {colors, setColors} = useContext(SystemContext);

    function handleColorEdit(event, key) {
        const color = event.target.innerText;
        console.log(color);
        if (validateColor(color)) {
            console.log("valid color");
            setColors({
                ...colors, [key]: color
            });
        } else {
            console.log("invalid color");
        }
    }
  return (
      <TableContainer>
          <Table sx={{minWidth: 650}} aria-label={"colors table"}>
              <TableHead>
                  <TableRow>
                      <TableCell>Chart key</TableCell>
                      <TableCell>Color</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {Object.entries(colors).map((row) => (<TableRow key={row[0]}>
                      <TableCell component="th" scope={"row"}>
                          {row[0]}
                      </TableCell>
                      <TableCell color={row[1]} onInputCapture={e => handleColorEdit(e, row[0])}
                                 contentEditable={true} component="th" scope={"row"}>
                          {row[1]}
                      </TableCell>
                      <CircleRounded sx={{mr: 3}} stroke={row[1]} htmlColor={row[1]}/>
                  </TableRow>))}
              </TableBody>
          </Table>
      </TableContainer>
  );
}