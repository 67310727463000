import {DataGrid, GridToolbar} from "@mui/x-data-grid";

import React from 'react';
import {Box} from "@mui/material";

export default function StatsDataGrid({data, columns}) {
    return (
        <Box sx={{
            height: 300, width: "100%", '& .theme--header': {
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.primary.light
                        : theme.palette.primary.dark
            }
        }}>
            <DataGrid pageSize={12} rowsPerPageOptions={[12]} sx={{m: 2}} columns={columns} rows={data} components={{Toolbar: GridToolbar}}/>
        </Box>
    );
}