import React, {useContext, useEffect} from 'react';
import {useState} from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import {MenuItem, Select, StepLabel, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DatePicker} from "@mui/lab";
import {START_DATE} from "../Constants/Constants";
import {SystemContext} from "../SystemContext";
import {retrieveCustomerStatsForDates} from "../CustomerStatsService";
import {processCustomerStats} from "../Utils/ProcessCustomerStats";
import StatsContentSection from "./sections/StatsContentSection";

const steps = ['Select dates', 'Select granularity', 'Generate report'];


const CreateReportPage = () => {
    const {viewSettings} = useContext(SystemContext);
    const [activeStep, setActiveStep] = useState(0);
    const [requestData, setRequestData] = useState({
        startDate: START_DATE, endDate: new Date(), view: viewSettings['primaryView']
    });

    const [report, setReport] = useState({});

    const [reportGenerated, setReportGenerated] = useState(false);

    React.useEffect(() => {
        handleViewChange(viewSettings['primaryView']);
    }, [viewSettings['primaryView']]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNewStartDate = (newStartDate) => {
        setRequestData({
            ...requestData, startDate: newStartDate
        })
    };
    const handleNewEndDate = (newEndDate) => {
        setRequestData({
            ...requestData, endDate: newEndDate
        })
    };
    const handleViewChange = (newView) => {
        setRequestData({
            ...requestData, view: newView
        })
    }

    const stepsCompleted = () => {
        return activeStep === steps.length - 1;
    }

    useEffect(() => {
        if (stepsCompleted()) {
            const view = viewSettings['availableViews'].find(view => view.name === requestData.view);
            retrieveCustomerStatsForDates(requestData.startDate, requestData.endDate, view).then(response => {
                const data = response.data;
                setReport(processCustomerStats(data));
            });
        } else {
            setReportGenerated(false);
        }
    }, [activeStep, requestData, viewSettings['availableViews']]);

    useEffect(() => {
        if (Object.keys(report).length === 0 && report.constructor === Object) {
            setReportGenerated(false);
        } else {
            console.log(report);
            setReportGenerated(true);
        }
    }, [report]);

    const StepDescription = ({activeStep}) => {
        switch (activeStep) {
            case 0:
                return (<Box sx={{mt: 5, mb: 3}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <DatePicker views={['day']} label={'Start date'} // inputFormat={"MMMM"}
                                        onChange={(newDate) => handleNewStartDate(newDate)}
                                        value={requestData.startDate}
                                        inputFormat={"dd/MM/yyyy"}
                                        renderInput={(params) => <TextField {...params}
                                                                            helperText={null}/>}/>
                            <DatePicker views={['day']} label={'End date'}
                                        onChange={(newDate) => handleNewEndDate(newDate)}
                                        value={requestData.endDate}
                                        disableFuture={true}
                                        inputFormat={"dd/MM/yyyy"}
                                        renderInput={(params) => <TextField {...params}
                                                                            helperText={null}/>}/>
                        </div>
                    </LocalizationProvider></Box>);
            case 1:
                return (
                    <Box display={"flex"} flexDirection={"row"} pt={2} justifyContent={"center"} sx={{mt: 5, mb: 3}}>
                        <Typography sx={{mr: 3}}>Select the granularity of the report</Typography>
                        <Select variant={"standard"} value={requestData.view}
                                onChange={event => handleViewChange(event.target.value)}>
                            {viewSettings['availableViews'].map(view => view.name).map(v => {
                                return <MenuItem value={v}>{v}</MenuItem>
                            })}
                        </Select></Box>);
            default:
                console.log(activeStep);
        }
        return null;
    }

    return (<Box sx={{width: '75%', ml: 'auto', mr: 'auto'}}>
        <Stepper activeStep={activeStep}>
            {steps.map(label => {
                const stepProps = {};
                const labelProps = {};
                stepProps.completed = false;
                return (<Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>);
            })}
        </Stepper>
        <React.Fragment>
            <StepDescription activeStep={activeStep}/>
            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'flex-end'}}>
                <Button color={'inherit'} disabled={activeStep === 0} onClick={handleBack} sx={{mr: 1}}>Back</Button>
                <Button color={'inherit'} disabled={activeStep === steps.length - 1} onClick={handleNext}>Next</Button>
            </Box>
        </React.Fragment>
        {reportGenerated ? (<StatsContentSection totalCommercial={report.totalCommercial}
                                                 totalNewTrial={report.newTrial}
                                                 totalTrial={report.totalTrial}
                                                 totalConversionsToComm={report.conversionsToCommercial}
                                                 totalFree={report.totalFree}
                                                 totalConversionsToFree={report.conversionsToFree}
                                                 newCommercial={report.newCommercial} upgrades={report.upgrades}
                                                 downgrades={report.downgrades}/>) : null}
    </Box>);
}

export default CreateReportPage;