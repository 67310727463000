import axios from 'axios'
import {getSession} from "./Auth/AuthService";
import {SERVER_URL, START_DATE} from "./Constants/Constants";

const REPORTS_API_URL = `${SERVER_URL}/customer-stats`;
const SAVED_REPORTS_API_URL = `${REPORTS_API_URL}/reports`;



export async function retrieveCustomerStats(view) {
    const session = await getSession();
    const authorization = 'Bearer ' + session.getAccessToken().getJwtToken();
    console.log(view);
    let fromDate = START_DATE;
    if (view['type'] === 'month') {
        fromDate = new Date(START_DATE.getFullYear(), START_DATE.getMonth(), 1);
    }
    return axios.get(REPORTS_API_URL, {
        params: {
            "fromDate": fromDate,
            "toDate": new Date(),
            "viewName": view['name'],
            "delta": view['delta'],
            "type": view['type']
        }, headers: {
            Authorization: authorization
        }
    });
}

export async function retrieveCustomerStatsForDates(startDate, endDate, view) {
    const session = await getSession();
    const authorization = 'Bearer ' + session.getAccessToken().getJwtToken();

    console.log(view);
    return axios.get(REPORTS_API_URL, {
        headers: {
            "Authorization": authorization
        }, params: {
            "fromDate": startDate,
            "toDate": endDate,
            "viewName": view['name'],
            "delta": view['delta'],
            "type": view['type']
        }
    });
}
export function getReportById(id) {
    return axios.get(SAVED_REPORTS_API_URL, {params: {id: id}});
}