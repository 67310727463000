import React from 'react';

import userPool from "./UserPool";
import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import {createContext} from "react";
import UserPool from "./UserPool";
import Pool from "./UserPool";

const AuthContext = createContext(null);

async function getSession() {
    return await new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.getSession((err, session) => {
                if (err) {
                    reject();
                } else {
                    resolve(session);
                }
            })
        } else {
            reject();
        }
    })
}

const AuthService = (props) => {
    const logout = () => {
        if (isLoggedIn()) {
            const user = UserPool.getCurrentUser();
            user.signOut();
        }
    }

    const isLoggedIn = () => {
        try {
            const user = UserPool.getCurrentUser();
            return !!user;
        } catch {
            return false;
        }
    };

    const resetPassword = (username) => {
        return new Promise((resolve, reject) => {
            if (username.length === 0 || userPool === null || userPool === undefined) {
                reject("Fill in all fields");
                console.log(username);
                return;
            }

            let userData = {
                Username: username, Pool: userPool
            };
            let cognitoUser = new CognitoUser(userData);

            cognitoUser.forgotPassword({
                onSuccess: function (result) {
                    console.log('call result: ' + result);
                    resolve(result);
                }, onFailure: function (err) {
                    console.log(err);
                    reject(err);
                }
            });
        });
    };

    const confirmPassword = (username, verificationCode, newPassword) => {
        let userData = {
            Username: username, Pool: userPool
        };
        let cognitoUser = new CognitoUser(userData);

        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onFailure(err) {
                    reject(err);
                }, onSuccess() {
                    resolve();
                },
            });
        });
    };

    const authenticateUser = async (email, password, onNewPasswordIsRequired) => {
        return await new Promise((resolve, reject) => {
            if (email.length === 0 || password.length === 0 || userPool === null || userPool === undefined) {
                reject("Fill in all fields");
                return;
            }
            let authData = {
                Username: email, Password: password
            };
            let authenticationDetails = new AuthenticationDetails(authData);

            let userData = {
                Username: email, Pool: userPool
            };
            let cognitoUser = new CognitoUser(userData);

            function completeChallenge(newPassword, requiredAttributes) {
                console.log("newPassword is: " + newPassword);
                cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributes, {
                    onSuccess: function (result) {
                        console.log("success complete new password challenge");
                        resolve(result);
                    }, onFailure: function (err) {
                        console.log("newPassword is: " + newPassword);
                        reject(err);
                    }
                });
            }

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                    resolve(result);
                }, onFailure: function (err) {
                    reject(err);
                }, newPasswordRequired: function (userAttributes, requiredAttributes) {
                    onNewPasswordIsRequired(function (newPassword) {
                        completeChallenge(newPassword, requiredAttributes)
                    })
                }
            });
        });
    }

    return (<AuthContext.Provider value={{authenticateUser, isLoggedIn, logout, resetPassword, confirmPassword}}>
        {props.children}
    </AuthContext.Provider>);
}

export {AuthService, getSession, AuthContext};