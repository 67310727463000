import React, {useState} from 'react';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {createTheme} from "@mui/material/styles";
import {useParams} from "react-router";
import {Link, NavLink} from "react-router-dom";
import {Collapse, List, ListItemButton, ListItemText} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

const theme = createTheme();

theme.typography.h3 = {
    fontSize: '1.2rem', '@media (min-width:600px)': {
        fontSize: '1.5rem',
    }, [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
    },
};

const MetricsList = () => {
    const [openTotalCommercialUsers, setOpenTotalCommercialUsers] = useState(true);
    const [openAnnualCommercial, setOpenAnnualCommercial] = useState(true);
    const [openMonthlyCommercial, setOpenMonthlyCommercial] = useState(true);

}

const HowDoesItWorkList = () => {
    const [openUserSnapshots, setOpenUserSnapshots] = useState(false);
    const [openGlobalSnapshots, setOpenGlobalSnapshots] = useState(false);
    const [openReport, setOpenReport] = useState(false);

    const handleUserSnapshotsSection = () => {
        setOpenUserSnapshots(!openUserSnapshots);
    }

    const handleGlobalSnapshotsSection = () => {
        setOpenGlobalSnapshots(!openGlobalSnapshots);
    }

    const handleReportSection = () => {
        setOpenReport(!openReport);
    }

    return (<List>
        <ListItemButton onClick={handleUserSnapshotsSection} sx={{mb: 1, background: "lightgrey"}}>
            <ListItemText primary="Generating user snapshots"/>
            {openUserSnapshots ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={openUserSnapshots} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItemText sx={{ml: 2}}
                              primary={<span><b>Since 24th of April, 2022</b>, each time a Studio 3T instance would ping License Manager or an update of a user's record in the License Manager would occur, the <b>new state of the said user would be logged</b>. We are calling this a <NavLink
                                  to={"/documentation/definitions"}><u>user snapshot</u></NavLink>.</span>}/>
            </List>
        </Collapse>
        <ListItemButton sx={{mb: 1, background: "lightgrey"}} onClick={handleGlobalSnapshotsSection}>
            <ListItemText primary="Generating global snapshots"/>
            {openGlobalSnapshots ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={openGlobalSnapshots} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItemText sx={{ml: 2}}
                              primary={<span>At the end of each day, <b>we collect information about the last known (up til that day) user snapshot</b>. This information includes: user id, the date when the user snapshot was generated,
                                  whether the snapshot indicates a trial or a free user, their account based license information (if it exists) and the date of last ping from Studio 3T. We are calling such pieces of information <NavLink
                                      to={"/documentation/definitions"}><u>global snapshots</u></NavLink>.</span>}/>
            </List>
        </Collapse>
        <ListItemButton sx={{mb: 1, background: "lightgrey"}} onClick={handleReportSection}>
            <ListItemText primary="Generating a report"/>
            {openReport ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={openReport} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItemText sx={{ml: 2}}
                              primary={<span>In order to create a report for a given interval of time (<i>note: </i> in order to compute spanning metrics such as conversions, two points in time are needed)
                                  we need to <b>retrieve the corresponding global snapshots</b> for the bounds of that interval. By doing this, <b>we now know the states in which the users were at those times</b> and we can already answer questions such as how many users converted from one state to another, but also how many users were in a certain state at each of these two times.</span>}/>
            </List>
        </Collapse>
    </List>);
}

const items = {
    "definitions": [{
        name: "Users Stats Application",
        description: <span>The <b>User Stats Application</b> was created in order to improve 3T Software Labs's internal data monitoring process.<br/>
            The system relies solely on data offered by the License Manager in the form of <b>user snapshots</b> and aims at offering visualisation means to the reports generated from the said user snapshots.<br/><br/>
            It provides multiple views based on granularity of the reports<br/><br/>
            All reports are relative to <b>24th of April, 2022</b> (the date representing the first date on which the system started generating user snapshots)</span>
    }, {
        name: "User Data Definitions", description: <span><h6>Valid Commercial User</h6>
            A user is said to be on a <b>valid commercial license</b> at the date of the report if and only if they have an account based license record in the license manager,
            the account based license indicates a commercial edition, it does not indicate a trial and the expiration date is after the date of the report.
            <br/><br/><h6>New Commercial User</h6>
            A user is said to be a new commercial user if and only if at the date of the prior report they did not have a valid commercial license.
            <br/><br/><h6>Free User</h6>
            A user is said to be a <b>free user</b> if and only if their Studio 3T instance contacted License Manager in the last 30 days prior to the date of the report and their License Manager record indicates they were using a free account at the date of the report.
            <br/><br/><h6>Trial User</h6>
            A user is said to be a <b>trial user</b> if and only if their Studio 3T instance contacted License Manager in the last 30 days prior to the date of the report and their License Manager record indicates they were using a trial at the date of the report.
        </span>
    }, {
        name: "Basic Technical Definitions", description: <span><h6>User snapshots</h6>
            User snapshots are records that act as logs of the registered states (w.r.t. License Manager) that a user has been through. They are <b>generated by the License Manager</b>.
        <br/><br/><h6>Global snapshots</h6>
            Global snapshots are <b>at the core of user stats app</b>. They are <b>generated daily</b> at midnight and represent the last known state of a user (based on user snapshots) up til the moment they are generated.
        </span>
    }], "the-app": [{
        name: "What Is Users Stats Application and How Does It Work?",
        description: <span>The <b>User Stats Application</b> was created in order to improve 3T Software Labs's internal data monitoring process.The system relies solely on data offered by the License Manager in the form of <NavLink
            to={"/documentation/definitions"}><u>user snapshots</u></NavLink> and aims at creating accurate statistics about users licenses data.
            <br/><br/> <h6>How does it work?</h6>
            <HowDoesItWorkList/>
            <br/><br/><h6>What are the metrics reported?</h6>

        </span>
    }]
};

export const DocumentationPage = () => {
    const docPage = useParams()["documentationPage"];
    console.log(docPage);

    return (<Box>
        {items[docPage].map((item, i) => <Item key={i} item={item}/>)}
    </Box>);
}

function Item(props) {
    return <React.Fragment>
        <Typography variant={'h5'} ml={1} mr={1} mb={1} mt={1}>{props.item.name}</Typography>
        <Divider sx={{mb: 2, ml: 1, mr: 1}}/>
        <Typography paragraph={true} variant={"body2"} ml={1} mr={1}>{props.item.description}</Typography>
    </React.Fragment>
}