import React, {useContext, useState} from 'react';
import Grid from "@mui/material/Grid";
import {Alert, LoadingButton} from '@mui/lab';
import StatsContentSection from "./sections/StatsContentSection";
import {SystemContext} from "../SystemContext";
import {AlertTitle} from "@mui/material";
import Box from "@mui/material/Box";
import {useCustomerStats} from "../Hooks/useCustomerStats";

const DashboardPage = () => {
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [serviceErr, setServiceErr] = useState({errorOccurred: false, errorType: "", errorMessage: ""});
    const {viewSettings} = useContext(SystemContext);
    const [activeView, setActiveView] = useState("");

    console.log(viewSettings);

    React.useEffect(() => {
        let view = viewSettings['availableViews'].find(view => view.name === viewSettings['primaryView']);
        setActiveView(view);
    }, [viewSettings]);

    const customerStatsData = useCustomerStats(activeView, () => {
        setIsPageLoaded(true);
        setServiceErr({errorOccurred: false, errorType: "", errorMessage: ""});
    }, (err) => {
        console.log(err)
        setServiceErr({
            ...serviceErr, errorOccurred: true,
        });
        if (err && err.hasOwnProperty('response')) {
            let errData = err.response.data;
            setServiceErr({
                ...serviceErr, errorOccurred: true, errorType: errData.error, errorMessage: errData.message
            })
        }
    });

    if (isPageLoaded) {
        return (
            <StatsContentSection totalCommercial={customerStatsData.totalCommercial}
                                 totalNewTrial={customerStatsData.newTrial}
                                 totalTrial={customerStatsData.totalTrial}
                                 totalConversionsToComm={customerStatsData.conversionsToCommercial}
                                 totalFree={customerStatsData.totalFree}
                                 totalConversionsToFree={customerStatsData.conversionsToFree}
                                 newCommercial={customerStatsData.newCommercial}
                                 upgrades={customerStatsData.upgrades} downgrades={customerStatsData.downgrades} illegalTrial={customerStatsData.illegalTrial}/>
        );
    } else {
        if (serviceErr.errorOccurred) {
            return (<Box sx={{display: "flex", flexGrow: 1, justifyContent: "center"}}>
                <Alert severity={"error"}>
                    <AlertTitle>Error</AlertTitle>
                    An error occurred while fetching customer stats data: <strong>{serviceErr.errorMessage}</strong>
                </Alert>
            </Box>);
        } else {
            return (<Grid container={true} alignItems={'center'} sx={{mr: "5px"}}>
                <Grid item={true} md={12}>
                    <LoadingButton fullWidth={true} loading aria-label={"Fetching Customer Stats Data"}
                                   variant={"outlined"} loadingPosition={'start'}>
                        Fetching Customer Stats Data
                    </LoadingButton>
                </Grid>
            </Grid>);
        }
    }
}

export default DashboardPage;