import * as d3 from "d3"
import React, {useContext, useState} from "react";
import {timeParse} from "d3-time-format";
import {Box} from "@mui/material";
import {DATE} from "../Constants/Constants";
import {SystemContext} from "../SystemContext";
import {spaceToDash} from "../Utils/HelperFunctions";
import {ChartContext} from "../Pages/sections/Charts";

const dateParser = timeParse("%Y-%m-%d");

const margin = {
    top: 30, right: 250, bottom: 50, left: 75,
};
const inactiveKeys = new Set();

function LineChart({data, keys, yAxisName}) {
    const wrapperRef = React.useRef(null);
    const {colors} = useContext(SystemContext);

    const [updateVar, setUpdateVar] = useState(0);
    const [dataSet, setDataSet] = useState(data);

    const {showGridTooltip, moveXOrigin, refresh} = useContext(ChartContext);

    console.log(showGridTooltip);

    function forceUpdate() {
        setUpdateVar(updateVar => updateVar + 1);
    }

    React.useEffect(() => {
        setDataSet(data);
    }, [data, refresh]);

    React.useEffect(() => {
        const xAccessor = d => dateParser(d[[DATE]]);
        let maxY = 0;
        // const width = 500;
        // const element = document.querySelector(".mb-4");
        const cardContent = document.querySelector(".card-content");
        const width = cardContent.offsetWidth;

        let dimensions = {
            width: width, height: 0.5 * width, margin: margin,
        }

        const legendMargin = {
            top: margin.top, bottom: 5, left: 28, right: 5
        }

        dimensions.boundedWidth = dimensions.width - dimensions.margin.left - dimensions.margin.right
        dimensions.boundedHeight = dimensions.height - dimensions.margin.top - dimensions.margin.bottom

        const wrapper = d3.select(wrapperRef.current);
        wrapper.select("svg").remove();

        const svg = (wrapper.select("svg").node() ? wrapper.select("svg") : wrapper.append("svg"));
        svg.attr("width", dimensions.width).attr("height", dimensions.height);
        const bounds = (svg.select("g").node() ? svg.select("g") : svg.append("g"))
            .style("transform", `translate(${dimensions.margin.left}px, ${dimensions.margin.top}px)`);
        svg.exit().remove();

        keys.filter(k => !inactiveKeys.has(k)).forEach(k => {
            const yAccessor = d => d[k];
            let localMaxY = d3.max(dataSet, yAccessor);
            maxY = d3.max([maxY, localMaxY]);
        });

        const yAccessor = (d, k) => d[k];
        const yScale = d3.scaleLinear().domain([0, maxY]).range([dimensions.boundedHeight, 0]);
        const lineGenerator = (data, key) => d3.line()
            .x(d => xScale(xAccessor(d)))
            .y(d => yScale(yAccessor(d, key)));

        const dateExtent = d3.extent(dataSet, xAccessor);
        const xScale = d3.scaleTime().domain([dateExtent[0], dateExtent[1]]).range([0, dimensions.boundedWidth]);

        const xAxisGenerator = d3.axisBottom().scale(xScale).tickValues(dataSet.map(d => xAccessor(d))).ticks(dataSet.length)
            .tickFormat(d3.timeFormat("%d-%m"));
        const yAxisGenerator = d3.axisLeft().scale(yScale);

        function generateChart() {
            keys.filter(k => !inactiveKeys.has(k)).forEach((k) => {
                const currPath = bounds.append("path");
                //data.filter(d => d[k] < yScale.domain()[1])
                currPath.datum(dataSet).attr("d", lineGenerator(dataSet, k))
                    .attr("fill", "none")
                    .attr("stroke", colors[k])
                    .attr("class", spaceToDash(k))
                    .attr("stroke-width", 2);

                const dots = bounds.append("g").attr("class", "dot");
                dots.selectAll("dot").data(dataSet).join("circle").attr("r", 5).attr("class", "circle")
                    .attr("cx", d => xScale(xAccessor(d)))
                    .attr("cy", d => yScale(yAccessor(d, k)))
                    .attr("stroke", colors[k])
                    .attr("fill", colors[k])
                    .on("mouseover", () => onMouseOverDataPoint())
                    .on("mousemove", event => onMouseMove(event, k))
                    .on("mouseleave", onMouseLeave)
                dots.selectAll("circle")
                    .on("mouseover", () => onMouseOverDataPoint())
                    .on("mousemove", event => onMouseMove(event, k))
                    .on("mouseleave", onMouseLeave);
            });
        }

        generateChart();

        let yAxis = bounds.append("g").attr("class", "yAxis").call(yAxisGenerator);
        const xAxis = bounds.append("g").call(xAxisGenerator).attr("class", "xAxis").style("transform", `translateY(${dimensions.boundedHeight}px)`);
        bounds.append("text")
            .attr("class", "y label")
            .attr("text-anchor", "end")
            .attr("y", -dimensions.margin.left)
            .attr("dy", ".75em")
            .attr("transform", "rotate(-90)")
            .text(yAxisName);

        yAxis.selectAll("g.yAxis g.tick").append("line")
            .attr("class", "gridline")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", dimensions.boundedWidth)
            .attr("y2", 0)
            .style('opacity', 0.3)
            .attr("stroke", "black")

        xAxis.selectAll("g.xAxis g.tick").append("line")
            .attr("class", "gridline")
            .attr("x1", 0)
            .attr("y1", -dimensions.boundedHeight)
            .attr("x2", 0)
            .attr("y2", 0)
            .style('opacity', 0.3)
            .attr("stroke", "black")

        yAxis.select(".tick").remove();
        yAxis.selectAll("g.yAxis g.tick").on("click", event => updateYAxis(event.target.__data__));

        if (showGridTooltip) {
            const hoverVerticalLines = xAxis.selectAll("g.xAxis g.tick").append("rect");
            hoverVerticalLines.attr("class", "hover-line").attr("fill", "none").attr("pointer-events", "all");
            hoverVerticalLines.attr("height", dimensions.boundedHeight).attr("width", 10).attr("y", -dimensions.boundedHeight).attr("x", -5);
            hoverVerticalLines.on("mouseover", (data) => onMouseOverGridLine(data))
                .on("mouseleave", (eventData) => {
                    onMouseLeave();
                    d3.select(eventData.target).style("fill", "none");
                });
        }

        if (moveXOrigin) {
            const hoverVerticalLines = xAxis.selectAll("g.xAxis g.tick").append("rect");
            hoverVerticalLines.attr("class", "hover-line").attr("fill", "none").attr("pointer-events", "all");
            hoverVerticalLines.attr("height", dimensions.boundedHeight).attr("width", 10).attr("y", -dimensions.boundedHeight).attr("x", -5);
            hoverVerticalLines.on("click", (data) => moveXOriginFun(data))
                .on("mouseover", onMouseOverGridLine)
                .on("mouseleave", eventData => d3.select(eventData.target).style("fill", "none"));
        }

        function updateYAxis(targetMaxYValue) {
            // TODO: Clean up this mess
            bounds.selectAll("g.yAxis g.tick").remove();
            bounds.selectAll("path").remove();
            bounds.selectAll("g.dot").remove();
            yScale.domain([0, targetMaxYValue]);
            // yAxis = bounds.append("g").attr("class", "yAxis").call(yAxisGenerator);
            yAxis.transition().duration(200).call(yAxisGenerator);
            yAxis.selectAll("g.yAxis g.tick").append("line")
                .attr("class", "gridline")
                .attr("x1", 0)
                .attr("y1", 0)
                .attr("x2", dimensions.boundedWidth)
                .attr("y2", 0)
                .attr("stroke", "black")
            generateChart();
            yAxis.selectAll("g.yAxis g.tick").on("click", event => updateYAxis(event.target.__data__));

        }

        const tooltip = d3.select("#chart-tooltip")
            .style("opacity", 0)
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "1px")
            .style("border-radius", "5px")
            .style("position", "absolute")
            .style("padding", "10px");

        svg.on("dblclick", () => updateYAxis(maxY))

        function onMouseOverDataPoint() {
            if (showGridTooltip) {
                return;
            }
            tooltip.style("opacity", 1);
        }

        function onMouseOverGridLine(eventData) {
            d3.select(eventData.target).style("fill", "lightblue").style("opacity", 0.25);
            if (!showGridTooltip) {
                return;
            }
            const dPoint = dataSet.find((el, i) => {
                return xAccessor(dataSet[i]).toString() === eventData.target.__data__.toString();
            });
            let tooltipDescription = `<b>Date</b>: ${dPoint[DATE]}<br/>`;
            keys.filter(k => !inactiveKeys.has(k)).map(k => {
                tooltipDescription = tooltipDescription.concat(`<b>${k}</b>: ${dPoint[k]}<br/>`)
            });

            tooltip.html(tooltipDescription).style("left", `${eventData.x}px`).style("top", `${eventData.y}px`).style("opacity", 1);
        }

        function moveXOriginFun(eventData) {
            let targetIndex = dataSet.indexOf(data.find(d => xAccessor(d).getTime() === eventData.target.__data__.getTime()));
            setDataSet(dataSet.slice(targetIndex, dataSet.length));
        }

        function onMouseMove(event, key) {
            if (showGridTooltip) {
                return;
            }
            const d = event.target.__data__;
            tooltip.html(`<b>Date</b>: ${d[DATE]}<br><b>${key}</b>: ${d[key]}`)
                .style("left", `${event.x}px`)
                .style("top", `${event.y}px`);
        }

        function onMouseLeave() {
            tooltip.style("opacity", 0).style("left", 0).style("top", 0);
        }

        // Legend
        const cellMargin = {
            left: 25, right: 5, top: 5, bottom: 5, margin: 2
        }

        const legend = svg.append("g").style("transform", `translate(${dimensions.boundedWidth + dimensions.margin.left + legendMargin.left}px, ${legendMargin.top}px)`).attr("font-family", "sans-serif");
        const colorScale = d3.scaleOrdinal().domain(keys).range(keys.map(k => colors[k]));
        const cell = legend.selectAll().data(colorScale.domain()).join("g");
        const squareSize = 14;
        cell.append('rect')
            .attr('fill', d => {
                if (inactiveKeys.has(d)) {
                    return "#A8A8A8";
                }
                return colorScale(d);
            })
            .attr('width', squareSize)
            .attr('height', squareSize)

        // add the text label for each entry
        cell.append('text')
            .attr('dominant-baseline', 'middle')
            .attr('x', squareSize * 1.5)
            .attr('y', squareSize / 2)
            .style('fill', d => {
                if (inactiveKeys.has(d)) {
                    return "#A8A8A8";
                }
                return "#000000";
            })
            .text(d => {
                return d;
            });

        function toggleElement(event) {
            const key = event.target.__data__;
            if (inactiveKeys.has(key)) {
                inactiveKeys.delete(key);
            } else {
                inactiveKeys.add(key);
            }
            forceUpdate();
        }

        legend.selectAll("g").on("click", event => toggleElement(event));

        // position the cells
        let yPosition = 0;

        cell.each(function (d, i) {
            d3.select(this)
                .attr('transform', `translate(0, ${yPosition})`);

            yPosition += (this.getBBox().height + squareSize + cellMargin.margin);
        });
    }, [colors, dataSet, keys, yAxisName, updateVar, showGridTooltip, moveXOrigin]);


    return (<Box className={"chart"} sx={{width: "80%", float: "left", mr: 2}} ref={wrapperRef}/>);

}

export default LineChart;