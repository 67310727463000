import React, {createContext, useState} from 'react';
import {
    Alert, Card, CardContent, CardHeader, Collapse, IconButton, MenuItem, Snackbar, Tooltip, Typography
} from "@mui/material";
import Menu from '@mui/material/Menu';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import RefreshIcon from '@mui/icons-material/Refresh';
import {convertToCsv} from "../../Utils/HelperFunctions";

const options = ['Export data to CSV', 'Export data to JSON'];

export const ChartContext = createContext(null);

export function ChartCard({chart, title, description = ""}) {
    const [showGridTooltip, setShowGridTooltip] = useState(false);
    const [moveXOrigin, setMoveXOrigin] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);

    // menu
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const open = Boolean(menuAnchorEl);
    const handleClickListItem = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const [openToastNotification, setOpenToastNotification] = useState(false);
    const [exportDataStatus, setExportDataStatus] = useState({});

    React.useEffect(() => {
        if (Object.keys(exportDataStatus).length !== 0) {
            setOpenToastNotification(true);
        }
    }, [exportDataStatus]);


    const handleMenuItemClick = (event, index) => {
        const exportSuccessful = () => {
            setExportDataStatus({
                ...exportDataStatus,
                "status": "success",
                "statusMessage": "Chart's data successfully copied to clipboard"
            });
        }
        const exportFailed = (err) => {
            console.log(err);
            setExportDataStatus({
                ...exportDataStatus, "status": "error", "statusMessage": "Failed to copy chart's data to clipboard"
            });
        }
        switch (index) {
            case 0:
                let csvData = convertToCsv(chart.props.data);
                navigator.clipboard.writeText(csvData).then(exportSuccessful).catch(err => {
                    exportFailed(err);
                });
                break;
            case 1:
                navigator.clipboard.writeText(JSON.stringify(chart.props.data)).then(exportSuccessful).catch(err => {
                    exportFailed(err);
                });
                break;
        }
        handleCloseMenu();
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const handleCloseNotification = () => {
        setOpenToastNotification(false);
    }

    const toggleShowTooltip = () => {
        setMoveXOrigin(false);
        setShowGridTooltip(!showGridTooltip);
    }

    const toggleMoveXOrigin = () => {
        setShowGridTooltip(false);
        setMoveXOrigin(!moveXOrigin);

    }

    const doRefresh = () => {
        setShowGridTooltip(false);
        setMoveXOrigin(false);
        setRefresh(refresh => refresh + 1);
    }

    return (<React.Fragment>
        <Card key={title} md={12} sx={{minWidth: 200, mt: 5, mb: 5}}>
            <CardHeader key={title}
                        action={<React.Fragment>
                            <Tooltip title={description} arrow={true}>
                                <IconButton key={description} aria-label={"description"} onClick={() => {
                                }}>
                                    <InfoOutlinedIcon/>
                                </IconButton>
                            </Tooltip>
                            <IconButton onClick={doRefresh}>
                                <RefreshIcon/>
                            </IconButton>
                            <IconButton color={moveXOrigin ? 'success' : 'inherit'} onClick={toggleMoveXOrigin}>
                                <TrendingFlatIcon/>
                            </IconButton>
                            <IconButton color={showGridTooltip ? 'success' : 'inherit'} onClick={toggleShowTooltip}>
                                <TouchAppIcon/>
                            </IconButton>
                            <IconButton key={title} aria-label={"options"} onClick={handleClickListItem}>
                                <DownloadForOfflineIcon/>
                            </IconButton>
                            <Menu
                                id="lock-menu"
                                anchorEl={menuAnchorEl}
                                open={open}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button', role: 'listbox',
                                }}
                            >
                                {options.map((option, index) => (<MenuItem
                                    key={option}
                                    // selected={index === selectedIndex}
                                    onClick={(event) => handleMenuItemClick(event, index)}
                                >
                                    {option}
                                </MenuItem>))}
                            </Menu>
                        </React.Fragment>}
                        title={<Typography align={"center"} onClick={toggleShow}>
                            {title}
                        </Typography>}
                        sx={{
                            backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.dark
                        }}
            />
            <Collapse orientation={'vertical'} collapsedSize={0} in={show}>
                <CardContent className={"card-content"}>
                    <ChartContext.Provider value={{showGridTooltip, moveXOrigin, refresh}}>
                        {chart}
                    </ChartContext.Provider>
                </CardContent>
            </Collapse>
        </Card>
        <Snackbar open={openToastNotification} autoHideDuration={1500}
                  onClose={handleCloseNotification} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
            <Alert onClose={handleCloseNotification} severity={exportDataStatus.status}
                   sx={{width: '100%'}}>
                {exportDataStatus.statusMessage}
            </Alert>
        </Snackbar>
    </React.Fragment>);
}