import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import {useNavigate} from "react-router";
import {useContext} from "react";

import {ExpandLess, ExpandMore, Logout} from "@mui/icons-material";
import {AuthContext} from "../Auth/AuthService";
import {Collapse, Icon, List} from "@mui/material";
import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';

export default function MainListItems({onClickCallback}) {
    const nav = useNavigate();
    const {isLoggedIn, logout} = useContext(AuthContext);
    const [openDocumentation, setOpenDocumentation] = React.useState(false);

    function handleClick(pageTitle, path) {
        nav(path);
        onClickCallback(pageTitle);
    }

    const toggleDocumentation = () => {
        setOpenDocumentation(!openDocumentation);
    }

    return (<React.Fragment>
        <ListItemButton onClick={() => handleClick("Dashboard", 'dashboard')}>
            <ListItemIcon>
                <DashboardIcon/>
            </ListItemIcon>
            <ListItemText primary="Dashboard"/>
        </ListItemButton>
        <ListItemButton onClick={() => handleClick('Create report', 'createReport')}>
            <ListItemIcon>
                <BarChartIcon/>
            </ListItemIcon>
            <ListItemText primary="Create report"/>
        </ListItemButton>
        <ListItemButton onClick={() => handleClick("System Configuration", "sysconfig")}>
            <ListItemIcon>
                <SettingsIcon/>
            </ListItemIcon>
            <ListItemText primary="System configuration"/>
        </ListItemButton>
        <ListItemButton onClick={toggleDocumentation}>
            <ListItemIcon>
                <ArticleIcon/>
            </ListItemIcon>
            <ListItemText primary="Documentation"/>
            {openDocumentation ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={openDocumentation} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItemButton onClick={() => handleClick("Definitions", "documentation/definitions")}>
                    <ListItemIcon>
                        <ArticleIcon fontSize={"small"} sx={{ml: 7}}/>
                    </ListItemIcon>
                    <ListItemText sx={{ml: 2}} primary="Definitions"/>
                </ListItemButton>
                <ListItemButton onClick={() => handleClick("The App", "documentation/the-app")}>
                    <ListItemIcon>
                        <ArticleIcon fontSize={"small"} sx={{ml: 7}}/>
                    </ListItemIcon>
                    <ListItemText sx={{ml: 2}} primary="The App"/>
                </ListItemButton>
            </List>
        </Collapse>
        {isLoggedIn() && <ListItemButton onClick={() => {
            logout();
            handleClick("Login", "login");
        }}>
            <ListItemIcon>
                <Logout/>
            </ListItemIcon>
            <ListItemText primary={"Logout"}/>
        </ListItemButton>}
    </React.Fragment>);
}