import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {
    DATE,
    DOWNGRADE_KEYS,
    NEW_COMMERCIAL_KEYS,
    TOTAL_COMMERCIAL_KEYS,
    TOTAL_CONVERSIONS_TO_COMM_KEYS,
    TOTAL_CONVERSIONS_TO_FREE_KEYS,
    UPGRADE_KEYS
} from "../../Constants/Constants";
import {
    AppBar,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogContent,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import {createTheme, ThemeProvider} from "@mui/material/styles";

const theme = createTheme({
    typography: {
        h5: {
            background: "lightgrey", textAlign: "center", fontWeight: 500, marginBottom: 3
        },
    }, components: {
        MuiButton: {
            variants: [{
                props: {variant: 'dashed'}, style: {
                    textTransform: 'none', border: `2px dashed black`, color: "black",
                },
            },]
        }, MuiTableCell: {
            styleOverrides: {
                root: {
                    textAlign: "center"
                }
            }
        }
    }
});

const DetailsDialogContent = ({cardDates, cardData}) => {
    return (<TableContainer component={Paper}>
        <Table sx={{minWidth: 650}}>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>{cardDates["date1"]}</TableCell>
                    <TableCell>{cardDates["date2"]}</TableCell>
                    <TableCell>
                        {cardDates["date1"]} <CompareArrowsIcon sx={{mr: 1, ml: 1}}/> {cardDates["date2"]}
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {cardData.map(cData => {
                    return (<React.Fragment>
                        <TableRow>
                            <TableCell>
                                <Typography variant={"h6"}>{cData["title"]}</Typography>
                            </TableCell>
                        </TableRow>
                        {cData["keys"].map(key => {
                            const dp1 = cData["dp1"];
                            const dp2 = cData["dp2"];
                            return (<TableRow key={key}>
                                <TableCell component={"th"} scope={"row"}>{key}</TableCell>
                                <TableCell>{dp1[key]}</TableCell>
                                <TableCell>{dp2[key]}</TableCell>
                                <TableCell>
                                    <Typography color={dp2[key] <= dp1[key] ? 'red' : 'green'}>
                                        {dp2[key] - dp1[key]}
                                        {dp2[key] <= dp1[key] ? <ArrowDropDownIcon color={'red'}/> :
                                            <ArrowDropUpIcon color={"green"}/>}
                                    </Typography>
                                </TableCell>
                            </TableRow>);
                        })}
                    </React.Fragment>);
                })}
            </TableBody>
        </Table>
    </TableContainer>);
}

const CommercialCard = ({cardTitle = "", cardDates, cardData}) => {
    const [showDetailsDialog, setShowDetailsDialog] = useState(false);

    // const totalCommercials1 = TOTAL_COMMERCIAL_KEYS.reduce((partialSum, v) => partialSum + dp1[v], 0);
    // const totalCommercials2 = TOTAL_COMMERCIAL_KEYS.reduce((partialSum, v) => partialSum + dp2[v], 0);
    //
    // const newCommercials1 = NEW_COMMERCIAL_KEYS.reduce((partialSum, v) => partialSum + dp1[v], 0);
    // const newCommercials2 = NEW_COMMERCIAL_KEYS.reduce((partialSum, v) => partialSum + dp2[v], 0);

    return (<Card sx={{height: 250, display: "flex", flexDirection: "column"}} variant={"outlined"}>
        <CardContent>
            <Typography textAlign={"center"} sx={{fontSize: 14, fontWeight: 500}} color={"text.secondary"}
                        gutterBottom={true}>
                <b>{cardDates["date1"]}</b> <CompareArrowsIcon sx={{mr: 1, ml: 1}}/> <b>{cardDates["date2"]}</b>
            </Typography>
            <Typography variant={"h5"} component={"div"}>{cardTitle}</Typography>
            <Grid container={true} maxWidth={280} spacing={1} justifyContent={"center"}>
                {cardData.map(cData => {
                    const total1 = cData["keys"].reduce((partialSum, v) => partialSum + cData["dp1"][v], 0);
                    const total2 = cData["keys"].reduce((partialSum, v) => partialSum + cData["dp2"][v], 0);
                    return (<React.Fragment>
                        <Grid item={true} xs={8} md={8}>
                            {cData["title"]}
                        </Grid>
                        <Grid item={true} xs={4} md={4} textAlign={"right"}>
                            <Typography color={total2 <= total1 ? 'red' : 'green'}>
                                {total2 - total1}
                                {total2 <= total1 ? <ArrowDropDownIcon color={'error'}/> :
                                    <ArrowDropUpIcon color={"success"}/>}
                            </Typography>
                        </Grid>
                    </React.Fragment>);
                })}
            </Grid>
        </CardContent>
        <CardActions sx={{justifyContent: "center", mt: "auto", mb: 0}}>
            <Button variant={"dashed"} onClick={() => setShowDetailsDialog(true)}><InfoIcon
                sx={{mr: 1, mb: 0}}/>Details</Button>
            <Dialog open={showDetailsDialog} fullScreen={true}>
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setShowDetailsDialog(false)}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            Details
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DetailsDialogContent cardData={cardData} cardDates={cardDates}/>
                </DialogContent>
            </Dialog>
        </CardActions>
    </Card>);
}

export const UpperDashboardSection = ({
                                          totalCommercial,
                                          newCommercial,
                                          totalConversionsToComm,
                                          totalConversionsToFree,
                                          totalTrial,
                                          totalFree,
                                          upgrades,
                                          downgrades
                                      }) => {

    const [commercialCard, setCommercialCard] = useState([]);
    const [cardDates, setCardDates] = useState({});
    React.useEffect(() => {
        setCommercialCard([{
            "title": "Total commercial users",
            "keys": TOTAL_COMMERCIAL_KEYS,
            "dp1": {...totalCommercial[totalCommercial.length - 2]},
            "dp2": {...totalCommercial[totalCommercial.length - 1]},
        }, {
            "title": "New commercial users",
            "keys": NEW_COMMERCIAL_KEYS,
            "dp1": {...newCommercial[newCommercial.length - 2]},
            "dp2": {...newCommercial[newCommercial.length - 1]}
        }]);
        if (totalCommercial.length > 1) {
            setCardDates({
                "date1": totalCommercial[totalCommercial.length - 2][DATE], // this is a trick, we should implement it properly
                "date2": totalCommercial[totalCommercial.length - 1][DATE]
            });
        }
    }, [totalCommercial, newCommercial])


    const conversionsCard = [{
        "title": "Conversions to commercial",
        "keys": TOTAL_CONVERSIONS_TO_COMM_KEYS,
        "dp1": {...totalConversionsToComm[totalConversionsToComm.length - 2]},
        "dp2": {...totalConversionsToComm[totalConversionsToComm.length - 1]}
    }, {
        "title": "Conversions to free",
        "keys": TOTAL_CONVERSIONS_TO_FREE_KEYS,
        "dp1": {...totalConversionsToFree[totalConversionsToFree.length - 2]},
        "dp2": {...totalConversionsToFree[totalConversionsToFree.length - 1]}
    }];

    const upgradesDowngradesCard = [{
        "title": "Upgrades",
        "keys": UPGRADE_KEYS,
        "dp1": {...upgrades[upgrades.length - 2]},
        "dp2": {...upgrades[upgrades.length - 1]},
    }, {
        "title": "Downgrades",
        "keys": DOWNGRADE_KEYS,
        "dp1": {...downgrades[downgrades.length - 2]},
        "dp2": {...downgrades[downgrades.length - 1]}
    }];


    return (<Grid sx={{flexGrow: 1}} justifyContent={"center"} container spacing={2}>
        <ThemeProvider theme={theme}>
            <Grid key={0} item xs={4}>
                <CommercialCard cardTitle={"Commercial Users"} cardDates={cardDates} cardData={commercialCard}/>
            </Grid>
            <Grid key={0} item xs={4}>
                <CommercialCard cardTitle={"Conversions"} cardDates={cardDates} cardData={conversionsCard}/>
            </Grid>
            <Grid key={0} item xs={4}>
                <CommercialCard cardTitle={"Upgrades / Downgrades"} cardDates={cardDates}
                                cardData={upgradesDowngradesCard}/>
            </Grid>
        </ThemeProvider>
    </Grid>);
}