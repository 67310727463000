import React from 'react';
import "./index.css"
import Dashboard from "./Components/Dashboard";
import {AuthService} from "./Auth/AuthService";
import {System} from "./SystemContext";

function App() {
    document.title = "Customer Stats";
    return (
        <div className="flexible-content">
            <AuthService>
                <System>
                    <Dashboard/>
                </System>
            </AuthService>
        </div>
    )
}

export default App;