import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import StatsDataGrid from "../../Components/StatsDataGrid";
import {mergeWithNewId} from "../../Utils/HelperFunctions";
import {ChartCard} from "./Charts";
import StackedAreaChart from "../../Components/StackedAreaChart";
import {
    DATE,
    DOWNGRADE_KEYS,
    NEW_COMMERCIAL_KEYS,
    TOTAL_COMMERCIAL_KEYS,
    TOTAL_CONVERSIONS_TO_COMM_KEYS,
    TOTAL_CONVERSIONS_TO_FREE_KEYS,
    TOTAL_FREE, TOTAL_ILLEGAL_TRIAL_KEYS,
    TOTAL_NEW_TRIAL,
    TOTAL_TRIAL,
    TRIAL_TO_FREE,
    UPGRADE_KEYS
} from "../../Constants/Constants";
import LineChart from "../../Components/LineChart";
import {UpperDashboardSection} from "./UpperDashboardSection";

export default function StatsContentSection({
                                                totalCommercial,
                                                totalTrial,
                                                totalNewTrial,
                                                totalFree,
                                                totalConversionsToComm,
                                                totalConversionsToFree,
                                                newCommercial,
                                                upgrades,
                                                downgrades,
                                                illegalTrial
                                            }) {
    let allKeys = [DATE];
    let chartsData = [];

    allKeys = allKeys.concat(...TOTAL_COMMERCIAL_KEYS);

    console.log(upgrades);

    chartsData.push({
        title: "Total Commercial Users (Stacked Line Chart)",
        chartElement: <StackedAreaChart yAxisName={"Total Commercial Users"} data={totalCommercial}
                                        keys={TOTAL_COMMERCIAL_KEYS}/>,
        description: <span>This chart displays information about the total number of users that were on a <b>valid commercial license</b> at the date of the report.<br/><br/>
            A user is said to be on a <b>valid commercial license</b> at the date of the report if and only if they have an account based license record in the license manager,
            the account based license indicates a commercial edition, it does not indicate a trial and the expiration date is after the date of the report.</span>
    });
    chartsData.push({
        title: "Total Commercial Users (Multi-Line Chart)",
        chartElement: <LineChart yAxisName={"Total Commercial Users"} data={totalCommercial}
                                 keys={TOTAL_COMMERCIAL_KEYS}/>,
        description: <span>This chart displays information about the total number of users that were on a <b>valid commercial license</b> at the date of the report.<br/><br/>
            A user is said to be on a <b>valid commercial license</b> at the date of the report if and only if they have an account based license record in the license manager,
            the account based license indicates a commercial edition, it does not indicate a trial and the expiration date is after the date of the report.</span>
    })

    allKeys = allKeys.concat(...NEW_COMMERCIAL_KEYS);

    chartsData.push({
        title: "New Commercial Users (Stacked Line Chart)",
        chartElement: <StackedAreaChart yAxisName={"New Commercial Users"} data={newCommercial}
                                        keys={NEW_COMMERCIAL_KEYS}/>,
        description: <span>This chart displays information about new commercial users.<br/><br/>
        A user is said to be a new commercial user if and only if at the date of the prior report they did not have a valid commercial license.<br/><br/>
        A user is said to be on a <b>valid commercial license</b> at the date of the report if and only if they have an account based license record in the license manager,
            the account based license indicates a commercial edition, it does not indicate a trial and the expiration date is after the date of the report.<br/><br/>
        <b><u><i>Note:</i></u></b> This chart would include users that had at the date of the prior report an expired commercial license, but have a valid one at the date of the current report.</span>
    });
    chartsData.push({
        title: "New Commercial Users (Multi-Line Chart)",
        chartElement: <LineChart yAxisName={"New Commercial Users"} data={newCommercial}
                                 keys={NEW_COMMERCIAL_KEYS}/>,
        description: <span>This chart displays information about new commercial users.<br/><br/>
        A user is said to be a new commercial user if and only if at the date of the prior report they did not have a valid commercial license.<br/><br/>
        A user is said to be on a <b>valid commercial license</b> at the date of the report if and only if they have an account based license record in the license manager,
            the account based license indicates a commercial edition, it does not indicate a trial and the expiration date is after the date of the report.<br/><br/>
        <b><u><i>Note:</i></u></b> This chart would include users that had at the date of the prior report an expired commercial license, but have a valid one at the date of the current report.</span>
    })

    allKeys = allKeys.concat(...TOTAL_CONVERSIONS_TO_COMM_KEYS);
    chartsData.push({
        title: "Total Conversions To Commercial (Stacked Line Chart)",
        chartElement: <StackedAreaChart yAxisName={"Total Conversions to Commercial"} data={totalConversionsToComm}
                                        keys={TOTAL_CONVERSIONS_TO_COMM_KEYS}/>
    });
    chartsData.push({
        title: "Total Conversions To Commercial (Multi-Line Chart)",
        chartElement: <LineChart yAxisName={"Total Conversions to Commercial"} data={totalConversionsToComm}
                                 keys={TOTAL_CONVERSIONS_TO_COMM_KEYS}/>
    })

    allKeys = allKeys.concat(...TOTAL_CONVERSIONS_TO_FREE_KEYS);
    chartsData.push({
        title: "Total Conversions To Free (Stacked Line Chart)",
        chartElement: <StackedAreaChart yAxisName={"Total Conversions to Free"} data={totalConversionsToFree}
                                        keys={TOTAL_CONVERSIONS_TO_FREE_KEYS}/>
    });
    chartsData.push({
        title: "Total Conversions To Free (Multi-Line Chart)",
        chartElement: <LineChart yAxisName={"Total Conversions to Free"} data={totalConversionsToFree}
                                 keys={TOTAL_CONVERSIONS_TO_FREE_KEYS}/>
    })

    allKeys = allKeys.concat([TOTAL_TRIAL]);
    if (!allKeys.includes(TRIAL_TO_FREE)) {
        allKeys.push(TRIAL_TO_FREE);
    }
    chartsData.push({
        title: "Total Trial Users", chartElement: <LineChart yAxisName={"Total Trial Users"} data={totalTrial}
                                                             keys={[TOTAL_TRIAL, TRIAL_TO_FREE]}/>
    });

    allKeys = allKeys.concat(...TOTAL_ILLEGAL_TRIAL_KEYS);
    chartsData.push({
        title: "Illegal Trialists",
        chartElement: <StackedAreaChart yAxisName={"Number of Illegal Trialists"} data={illegalTrial}
                                 keys={TOTAL_ILLEGAL_TRIAL_KEYS}/>
    })

    allKeys = allKeys.concat([TOTAL_NEW_TRIAL]);
    chartsData.push({
        title: "New Trial Users", chartElement: <LineChart yAxisName={"New Trial Users"} data={totalNewTrial}
                                                           keys={[TOTAL_NEW_TRIAL]}/>
    });

    allKeys = allKeys.concat([TOTAL_FREE]);
    chartsData.push({
        title: "Total Free Users",
        chartElement: <LineChart yAxisName={"Total Free Users"} data={totalFree} keys={[TOTAL_FREE]}/>
    })

    allKeys = allKeys.concat(...UPGRADE_KEYS);
    chartsData.push({
        title: "Commercial Upgrades - Annual Only (Stacked Line Chart)",
        chartElement: <StackedAreaChart yAxisName={"Total Commercial Upgrades"} data={upgrades} keys={UPGRADE_KEYS}/>,
        description: <span>This chart displays information about the total number of commercial upgrades. A commercial upgrade is a conversion between a valid paid licenses to a more expensive paid license (e.g. Basic to Pro) <br/><br/>
        <b>Note: </b> Monthly subscriptions are not taken into consideration for the purposes of building this chart</span>
    });
    chartsData.push({
        title: "Commercial Upgrades - Annual Only (Multi-Line Chart)",
        chartElement: <LineChart yAxisName={"Total Commercial Upgrades"} data={upgrades} keys={UPGRADE_KEYS}/>,
        description: <span>This chart displays information about the total number of commercial upgrades. A commercial upgrade is a conversion between a valid paid licenses to a more expensive paid license (e.g. Basic to Pro) <br/><br/>
        <b>Note: </b> Monthly subscriptions are not taken into consideration for the purposes of building this chart</span>
    });

    allKeys = allKeys.concat(...DOWNGRADE_KEYS);
    chartsData.push({
        title: "Commercial Downgrades - Annual Only (Stacked Line Chart)",
        chartElement: <StackedAreaChart yAxisName={"Total Commercial Downgrades"} data={downgrades}
                                        keys={DOWNGRADE_KEYS}/>,
        description: <span>This chart displays information about the total number of commercial downgrades. A commercial downgrade is a conversion between a valid paid licenses to a less expensive paid license (e.g. Pro to Basic) <br/><br/>
        <b>Note: </b> Monthly subscriptions are not taken into consideration for the purposes of building this chart</span>
    });
    chartsData.push({
        title: "Commercial Downgrades - Annual Only (Multi-Line Chart)",
        chartElement: <LineChart yAxisName={"Total Commercial Downgrades"} data={downgrades} keys={DOWNGRADE_KEYS}/>,
        description: <span>This chart displays information about the total number of commercial upgrades. A commercial upgrade is a conversion between a valid paid licenses to a more expensive paid license (e.g. Basic to Pro) <br/><br/>
        <b>Note: </b> Monthly subscriptions are not taken into consideration for the purposes of building this chart</span>
    });

    return (<Container maxWidth={'xl'} sx={{mt: 0, mb: 4}}>
        <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} md={12} lg={12}>
                <UpperDashboardSection
                    totalCommercial={totalCommercial} newCommercial={newCommercial}
                    totalConversionsToComm={totalConversionsToComm} totalConversionsToFree={totalConversionsToFree}
                    totalTrial={totalTrial} totalFree={totalFree} upgrades={upgrades} downgrades={downgrades}/>
            </Grid>
            <Grid item={true} xs={12} md={12} lg={12}>
                <div id={"chart-tooltip"} style={{width: 300, maxWidth: 350}}/>
                <StatsDataGrid
                    data={mergeWithNewId(mergeWithNewId(mergeWithNewId(mergeWithNewId(mergeWithNewId(mergeWithNewId(mergeWithNewId(mergeWithNewId(totalCommercial, totalTrial), totalNewTrial), totalConversionsToComm), totalFree), totalConversionsToFree), newCommercial), upgrades), downgrades)}
                    columns={allKeys.map(d => {
                        return {
                            field: d,
                            headerAlign: 'center',
                            align: 'center',
                            width: 150,
                            headerClassName: 'theme--header'
                        }
                    })}/>
            </Grid>
            <Grid className={"chart-column"} item={true} xs={12} md={12} lg={12}>
                {chartsData.map(cData => {
                    return (
                        <ChartCard title={cData.title} chart={cData.chartElement} description={cData.description}/>);
                })}
            </Grid>
        </Grid>
    </Container>);
}