export const TOTAL_COMMERCIAL_ANNUAL_BASIC = "Basic (annual)";
export const TOTAL_COMMERCIAL_ANNUAL_PRO = "Pro (annual)";
export const TOTAL_COMMERCIAL_ANNUAL_ULTIMATE = "Ultimate (annual)";
export const TOTAL_COMMERCIAL_MONTHLY_BASIC = "Basic (monthly)";
export const TOTAL_COMMERCIAL_MONTHLY_PRO = "Pro (monthly)";

export const NEW_COMMERCIAL_MONTHLY_BASIC = "New Basic (monthly)";
export const NEW_COMMERCIAL_MONTHLY_PRO = "New Pro (monthly)";
export const NEW_COMMERCIAL_ANNUAL_BASIC = "New Basic (annual)";
export const NEW_COMMERCIAL_ANNUAL_PRO = "New Pro (annual)";
export const NEW_COMMERCIAL_ANNUAL_ULTIMATE = "New Ultimate (annual)";

export const TOTAL_TRIAL = "Total trial users";
export const TOTAL_NEW_TRIAL = "New trial users"
export const TOTAL_FREE = "Total free users";
export const TRIAL_TO_BASIC_MONTHLY = "Trial to Basic (Monthly)";
export const TRIAL_TO_PRO_MONTHLY = "Trial to Pro (Monthly)";
export const TRIAL_TO_BASIC_ANNUAL = "Trial to Basic (Annual)";
export const TRIAL_TO_PRO_ANNUAL = "Trial to Pro (Annual)";
export const TRIAL_TO_ULTIMATE_ANNUAL = "Trial to Ultimate (Annual)";

export const FREE_TO_BASIC_MONTHLY = "Free to Basic (Monthly)";
export const FREE_TO_PRO_MONTHLY = "Free to Pro (Monthly)";
export const FREE_TO_BASIC_ANNUAL = "Free to Basic (Annual)";
export const FREE_TO_PRO_ANNUAL = "Free to Pro (Annual)";
export const FREE_TO_ULTIMATE_ANNUAL = "Free to Ultimate (Annual)";

export const FIRST_USAGE_TO_BASIC_MONTHLY = "First Usage to Basic (Monthly)";
export const FIRST_USAGE_TO_PRO_MONTHLY = "First Usage to Pro (Monthly)";
export const FIRST_USAGE_TO_BASIC_ANNUAL = "First Usage to Basic (Annual)";
export const FIRST_USAGE_TO_PRO_ANNUAL = "First Usage to Pro (Annual)";
export const FIRST_USAGE_TO_ULTIMATE_ANNUAL = "First Usage to Ultimate (Annual)";

export const BASIC_MONTHLY_TO_FREE = "Basic (Monthly) to Free";
export const PRO_MONTHLY_TO_FREE = "Pro (Monthly) to Free";
export const BASIC_ANNUAL_TO_FREE = "Basic (Annual) to Free";
export const PRO_ANNUAL_TO_FREE = "Pro (Annual) to Free";
export const ULTIMATE_ANNUAL_TO_FREE = "Ultimate (Annual) to Free";
export const TRIAL_TO_FREE = "Trial to Free";
export const POC_TO_FREE = "PoC to Free";
export const EDUCATIONAL_TO_FREE = "Educational to Free";
export const NON_COMM_TO_FREE = "Non-commercial to Free";

export const BASIC_TO_PRO = "Basic to Pro";
export const BASIC_TO_ULTIMATE = "Basic to Ultimate";
export const PRO_TO_ULTIMATE = "Pro to Ultimate";

export const ULTIMATE_TO_PRO = "Ultimate to Pro";
export const ULTIMATE_TO_BASIC = "Ultimate to Basic";
export const PRO_TO_BASIC = "Pro to Basic";

export const ILLEGAL_TRIAL_3_DAYS = "< 3 days";
export const ILLEGAL_TRIAL_7_DAYS = "< 7 days";
export const ILLEGAL_TRIAL_30_DAYS = "< 30 days";
export const ILLEGAL_TRIAL_30_PLUS_DAYS = "> 30 days";

export const TOTAL_COMMERCIAL_KEYS = [
    [TOTAL_COMMERCIAL_ANNUAL_BASIC],
    [TOTAL_COMMERCIAL_ANNUAL_PRO],
    [TOTAL_COMMERCIAL_ANNUAL_ULTIMATE],
    [TOTAL_COMMERCIAL_MONTHLY_BASIC],
    [TOTAL_COMMERCIAL_MONTHLY_PRO]
];

export const UPGRADE_KEYS = [
    [BASIC_TO_ULTIMATE],
    [BASIC_TO_PRO],
    [PRO_TO_ULTIMATE]
];

export const DOWNGRADE_KEYS = [
    [ULTIMATE_TO_PRO],
    [ULTIMATE_TO_BASIC],
    [PRO_TO_BASIC]
]

export const NEW_COMMERCIAL_KEYS = [
    [NEW_COMMERCIAL_MONTHLY_BASIC],
    [NEW_COMMERCIAL_MONTHLY_PRO],
    [NEW_COMMERCIAL_ANNUAL_BASIC],
    [NEW_COMMERCIAL_ANNUAL_PRO],
    [NEW_COMMERCIAL_ANNUAL_ULTIMATE]
]

export const TOTAL_CONVERSIONS_TO_COMM_KEYS = [
    [TRIAL_TO_BASIC_MONTHLY],
    [TRIAL_TO_PRO_MONTHLY],
    [TRIAL_TO_BASIC_ANNUAL],
    [TRIAL_TO_PRO_ANNUAL],
    [TRIAL_TO_ULTIMATE_ANNUAL],
    [FREE_TO_BASIC_MONTHLY],
    [FREE_TO_PRO_MONTHLY],
    [FREE_TO_BASIC_ANNUAL],
    [FREE_TO_PRO_ANNUAL],
    [FREE_TO_ULTIMATE_ANNUAL],
    [FIRST_USAGE_TO_BASIC_MONTHLY],
    [FIRST_USAGE_TO_PRO_MONTHLY],
    [FIRST_USAGE_TO_BASIC_ANNUAL],
    [FIRST_USAGE_TO_PRO_ANNUAL],
    [FIRST_USAGE_TO_ULTIMATE_ANNUAL],
]

export const TOTAL_CONVERSIONS_TO_FREE_KEYS = [
    [TRIAL_TO_FREE],
    [BASIC_MONTHLY_TO_FREE],
    [PRO_MONTHLY_TO_FREE],
    [BASIC_ANNUAL_TO_FREE],
    [PRO_ANNUAL_TO_FREE],
    [ULTIMATE_ANNUAL_TO_FREE],
    [POC_TO_FREE],
    [EDUCATIONAL_TO_FREE],
    [NON_COMM_TO_FREE]
]

export const TOTAL_ILLEGAL_TRIAL_KEYS = [
    ILLEGAL_TRIAL_3_DAYS,
    ILLEGAL_TRIAL_7_DAYS,
    ILLEGAL_TRIAL_30_DAYS,
    ILLEGAL_TRIAL_30_PLUS_DAYS
]

export const DATE = "Date";

export const START_DATE = new Date(2022, 4, 28);

export const SERVER_URL = 'https://user-stats-api.3t.io';
// export const SERVER_URL = 'http://localhost:8080';